<template>
  <div class="row-fields">
    <p class="row-fields__description">
      Поля:
    </p>
    <ul class="row-fields-list">
      <li
        v-for="(fieldName, index) in mainFields"
        :key="index"
        class="row-fields-item"
      >
        <div
          class="wrapper-filter"
        >
          <img
            alt="mark"
            src="/dist/css/images/tick_small.png"
            class="row-fields__mark"
          >
          <p class="row-fields__text">
            {{ fieldName }}
          </p>
        </div>
      </li>

      <li
        v-if="extraFields.length > 0"
        class="row-fields-item"
      >
        <div class="wrapper-filter">
          <img
            alt="mark"
            src="/dist/css/images/tick_small.png"
            class="row-fields__mark"
          >
          <p class="row-fields__text row-fields__text_other">
            <span class="toggle-extra-fields-tooltip">и другие данные</span>
            <span class="row-fields__extra">
              <span
                v-for="(extraField, index) in extraFields"
                :key="index"
                class="row-fields__extra-item"
              >
                {{ extraField }}
              </span>
            </span>
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RowFields',

  props: {
    mainFields: {
      type: Array,
      required: true,
    },

    extraFields: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>

<style>
.row-fields {
  font-weight: bold;
  display: grid;
  grid-template-columns: 130px 1fr;
  justify-items: start;
  align-items: flex-start;
}

.wrapper-filter {
  display: flex;
  align-items: center;
  font-weight: normal;
}

.row-fields__text {
  line-height: 1.3em;
}

.row-fields__text_other {
  position: relative;
  border-bottom: 1px dashed;
  cursor: pointer;
}

.toggle-extra-fields-tooltip:hover + .row-fields__extra {
  display: flex;
}

.row-fields__extra {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  position: absolute;
  z-index: 100;
  left: 0;
  bottom: 0;
  transform: translateY(103%);
  width: 180px;
  padding: 10px;
  border: 1px solid #999;
  border-radius: 4px;
  color: #353535;
  background: var(--notification-color);
  font-size: 13px;
  line-height: 1.2;
}

.row-fields__extra-item {
  padding-left: 16px;
  background-image: url("/dist/css/images/tick_small.png");
  background-position: left center;
  background-repeat: no-repeat;
}
</style>
