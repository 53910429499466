<template>
  <div class="description-list">
    <RowFields
      :main-fields="getMainFields()"
      :extra-fields="getExtraFields"
    />
    <RowFormat />
    <RowRelevant />
    <RowBase />
    <RowKp />
  </div>
</template>

<script>

import RowFields from '@/common_components/PayPopup/OrderDescription/RowFields.vue';
import RowKp from '@/main_base_pay_popup/components/Description/RowKp.vue';
import RowRelevant from '@/main_base_pay_popup/components/Description/RowRelevant.vue';
import RowFormat from '@/main_base_pay_popup/components/Description/RowFormat.vue';
import RowBase from '@/main_base_pay_popup/components/Description/RowBase.vue';

export default {
  name: 'Description',
  components: {
    RowBase,
    RowKp,
    RowRelevant,
    RowFormat,
    RowFields,
  },

  computed: {
    getExtraFields() {
      return [
        'Рубрика и подрубрика',
        'Тип подрубрики (главная или дополнительная)',
        'Город и регион',
        'Координаты на карте',
        'Часовой пояс',
        'Время работы',
        'Рейтинг компании в Интернете',
      ];
    },
  },

  methods: {
    getMainFields() {
      const defaultFields = ['Название компании'];

      const fieldNames = {
        phone: 'Телефон компании',
        email: 'E-mail компании',
        address: 'Адрес и индекс',
        site: 'Сайт / соц.сети',
        inn: 'ИНН / ОГРН / Руководитель',
        show_company_size: 'Числен./выручка',
      };
      const optionalFields = (window.getFilterConfig().basic)
        .filter((field) => field in fieldNames)
        .map((field) => fieldNames[field]);

      return [...defaultFields, ...optionalFields];
    },
  },
};
</script>

<style scoped>
.description-list{
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  color: black;
}
</style>
