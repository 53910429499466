import { createApp } from 'vue';
import initSentry from '@/vue_sentry';
import MainBasePayPopup from '@/main_base_pay_popup/components/MainBasePayPopup.vue';
import store from './store/index';

const app = createApp(MainBasePayPopup);

initSentry(app);

app.use(store);

window.vue_main_base_pay_popup = app.mount('#vue_main_base_pay_popup');
