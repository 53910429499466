<template>
  <div class="row-kp">
    <p class="row-title">
      КП на выгрузку:
    </p>
    <p class="row__link _com_offer_init _extra_tool">
      Получить коммерческое
    </p>
  </div>
</template>

<script>
export default {
  name: 'RowKp',
}
</script>

<style scoped>
.row-kp {
  font-weight: bold;
  display: grid;
  grid-template-columns: 130px 1fr;
  justify-items: start;
  align-items: center;
  margin-top: 10px;
}

.row__link {
  border-bottom: 1px dashed;
  color: var(--color-button-prime);
  cursor: pointer;
  text-decoration: none;
  transition: .15s ease-in-out;
}
</style>
