import axios from "axios";

export default {
  createOrder(data) {
    return fetch('/api/main_base/payment_main_base', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .catch(() => echo(
        'Произошла ошибка! Просим связаться с тех. поддержкой по тел. 8 800 775-29-12 '
            + 'или написать на info@export-base.ru',
        6000,
        'error',
      ));
  },

  getUserData() {
    return fetch('/api/authorized_client_data', { method: 'GET' })
      .then(res => res.json());
  },

  checkAuth() {
    return fetch('api/client_is_authenticated', { method: 'GET' })
      .then(res => res.json());
  },

  getUserDiscount(email) {
    return fetch('/handlers/email_discount.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        email: email,
      }),
    })
        .then(res => res.json())
        .catch(() => {
          echo(
              `Произошла ошибка! Пожалуйста, обратитесь в тех. поддержку: 8 (800) 775 - 29 - 12 (доб. 1)`,
              6000,
              'error',
          );
        })
  },

  createDemoLink(data) {
    return fetch('/api/main_base/create_demo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        data,
      ),
    })
      .then(res => res.json())
      .catch(() => {
        echo(
          'Произошла ошибка! Пожалуйста, обратитесь в тех. поддержку: 8 (800) 775 - 29 - 12 (доб. 1)',
          6000,
          'error',
        );
      });
  },
};
