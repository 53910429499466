<template>
  <div>
    <PayPopup
      :visible-pay-popup="visiblePayPopup"
      :email="email"
      :initial-section="initialSection"
      :order-id="orderId"
      :cost="cost"
      :customer-is-authorized="customerIsAuthorized"
      :demo-creation-is-running="demoCreationIsRunning"
      :demo-created-successfully="demoCreatedSuccessfully"
      :show-order-preloader="showOrderPreloader"
      :count-companies="countCompanies"
      :user-discount="userDiscount"
      :client-id="clientId"
      :client-balance="clientBalance"
      :initial-tab="initialTab"
      with-demo-section
      with-save-section
      with-promocode
      payment-target="database"
      service="database"
      order-type="main_base"
      @close-popup="closePayPopup"
      @input-email="changeEmail"
      @init-login="initLogin"
      @create-bill="createBill"
      @create-order="createOrder"
      @download-demo="downloadDemoFile"
      @save-order="saveOrder"
    >
      <template v-slot:description>
        <Description />
      </template>
    </PayPopup>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import PayPopup from '@/common_components/PayPopup/PayPopup.vue';
import Description from '@/main_base_pay_popup/components/Description/Description.vue';

export default {
  name: 'MainBasePayPopup',
  components: {
    Description,
    PayPopup,
  },

  computed: {
    ...mapState([
      'visiblePayPopup',
      'email',
      'clientId',
      'clientBalance',
      'orderId',
      'countCompanies',
      'userDiscount',
      'cost',
      'customerIsAuthorized',
      'initialSection',
      'demoCreationIsRunning',
      'demoCreatedSuccessfully',
      'showOrderPreloader',
      'initialTab',
    ]),

    ...mapGetters([
      'getUrlForBill',
    ]),
  },

  methods: {
    ...mapMutations([
      'setVisiblePayPopup',
      'setEmail',
      'setOldBeznalData',
      'setDemoCreationIsRunning',
      'setDemoCreatedSuccessfully',
      'setInitialSection',
      'setShowOrderPreloader',
      'setCost',
      'setOrderId',
      'setInitialTab',
    ]),

    ...mapActions({
      createOrderStore: 'createOrder',
      saveOrderStore: 'saveOrder',
    }),

    ...mapActions([
      'getAuthStatus',
      'getUserData',
      'getDemoFileHash',
      'getDataToCreateOrder',
      'getDataToCreateBill',
      'getDataForDemo',
      'getUserDiscount',
      'setDemoHash',
    ]),

    closePayPopup() {
      this.setOrderId('');
      this.setVisiblePayPopup(false);
      this.setInitialSection('');
      this.setInitialTab('');
    },

    async saveOrder(email) {
      const clonedData = Object.assign({}, await this.getDataToCreateOrder());
      clonedData.plata = 3;
      clonedData.email = email;
      await this.saveOrderStore(clonedData);
      echo('Ваш заказ сохранен и доступен для оплаты в личном кабинете.', 6000, 'success');
    },

    changeEmail(email) {
      this.setEmail(email);
      this.getUserDiscount(email);
    },

    async createOrder() {
      this.setShowOrderPreloader(true);

      const order = await this.createOrderStore(await this.getDataToCreateOrder());
      window.openWebSocketChannel(order.id_plan, order.hash);

      this.setShowOrderPreloader(false);
    },

    async createBill(data) {
      this.setOldBeznalData(data);
      await this.createOrderStore(await this.getDataToCreateBill());

      if (window.MFuncs.goYandexKassaWithParams) {
        window.MFuncs.goYandexKassaWithParams(this.orderId, this.cost);
      }
      if (window.MFuncs.yandexReachGoalWithTarget) {
        window.MFuncs.yandexReachGoalWithTarget('beznal');
      }

      // костыль для лучшего достижения целей при медленном интернете
      // переходим на страницу счета с небольшой задержкой, чтобы цели дошли до Яндекса
      setTimeout(() => {
        window.location.href = this.getUrlForBill;
      }, 100);
    },

    async downloadDemoFile({format, option, email}) {
      this.setDemoCreationIsRunning(true);
      let dataForDemo = await this.getDataForDemo();
      dataForDemo.format = format;
      dataForDemo.email = email;
      const optionText = option ? '&options=hyperlinks_phone' : '';
      const { message, error } = await this.getDemoFileHash(dataForDemo);

      this.setDemoCreationIsRunning(false);
      if (error) {
        this.setDemoCreatedSuccessfully(false);
        return echo(message, 6000, 'warning');
      }
      this.setDemoCreatedSuccessfully(true);

      this.setDemoHash(message);

      location.href = '/api/main_base/create_demo_file?code='
            + message + '&format=' + format + optionText;

      window.echo(
        'Демо-выгрузка сформирована и скачивается. Ссылка отправлена на ваш E-mail',
        6000,
        'success',
      );
    },

    addEventForBalanceBlock() {
      document.body.addEventListener('open-balance-block', async () => {
        await this.getAuthStatus();
        if (this.customerIsAuthorized) {
          await this.getUserData();
        }
        this.setInitialTab('BalanceForm');
        await this.setVisiblePayPopup(true);
        this.setInitialTab('');
      });
    },

    initLogin() {
      window.open_balance_block = true;
      this.addEventForBalanceBlock();
      this.setVisiblePayPopup(false);
      document.dispatchEvent(new Event('openAuthWrapper'));
    },
  },
};
</script>
